import { useEffect, useState } from 'react'
import { Alert, Button, Collapse, Modal, Toast } from 'react-daisyui'
import { useAuth } from 'react-oidc-context'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import useDeviceService from '../../hooks/useDeviceService'
import { Device } from '../../models/device.model'
import { useAppDispatch } from '../../store/hooks/hooks'
import { closeDeviceLibraryModal, openCreateDeviceModal } from '../../store/slices/modal.slice'

interface DeviceLibraryModalProps {
  isOpen: boolean
  projectId: string
}

function DeviceLibraryModal({ isOpen, projectId }: DeviceLibraryModalProps): JSX.Element {
  const deviceService = useDeviceService()
  const [allDevices, setAllDevices] = useState<Device[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')

  const categories: string[] = []
  const auth = useAuth()

  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetchDevices = async (): Promise<void> => {
      if (auth.user?.access_token) {
        const devices = await deviceService.getAllDevices()
        setAllDevices(devices)
      } else {
        console.log('Authorization token not available')
      }
    }
    fetchDevices().catch(() => {
      console.log('An error occurred')
    })
  }, [auth.user?.access_token])

  allDevices.forEach((device: Device) => {
    device.categories.forEach((category) => {
      if (!categories.includes(category)) {
        categories.push(category)
      }
    })
  })

  const getDeviceComponent = (device: Device): JSX.Element => (
    <button
      type="button"
      key={device.uuid}
      className="text-left my-1"
      onClick={() => {
        deviceService.addDeviceToCanvas(device, projectId).catch((error) => {
          console.log('An error occurred', error)
          setErrorMessage(error.message)
        })
      }}
    >{`${device.manufacturer} ${device.model}`}</button>
  )

  const getCategorySection = (category: string): JSX.Element => (
    <Collapse checkbox className="bg-gray-100 my-3" icon="plus" key={`${category}CategorySection`}>
      <Collapse.Title className="cursor-pointer text-xl font-medium">{category}</Collapse.Title>
      <Collapse.Content className="flex flex-col">
        {allDevices
          // Filter for all devices in the category and map those to the device component
          .filter((device) => device.categories.includes(category))
          .map((device) => getDeviceComponent(device))}
      </Collapse.Content>
    </Collapse>
  )

  return (
    <Modal
      className="bg-blue-main max-w-5xl absolute top-10 px-10 overflow-hidden"
      open={isOpen}
      backdrop
    >
      {errorMessage ? (
        <Toast vertical="top" horizontal="end" className="mt-1 mr-5">
          <Alert
            status="error"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            }
          >
            <span>Error! {errorMessage}</span>
          </Alert>
        </Toast>
      ) : null}
      <Modal.Header className="flex flex-row items-start text-white font-medium text-2xl">
        Select a device
      </Modal.Header>
      <Modal.Body className="flex-1 overflow-y-auto">
        <div className="flex flex-row rounded-md max-w-screen-xl">
          <div
            className="flex flex-col bg-white text-black rounded-md px-6 p-8
               w-full overflow-y-scroll max-h-[calc(100vh-300px)]"
          >
            <div className="flex flex-row justify-between pb-2 ">
              <div className="text-left w-max mb-8 font-bold self-start">
                <div className="mb-3">General Device Library </div>
                <i className="text-left font-normal text-sm">
                  Right now, we only support one device library for everyone.
                  <br />
                  But stay tuned, your personal library will come with future versions of plantypus!
                </i>
              </div>
              <Button
                id="plus"
                type="button"
                startIcon={<PlusCircleIcon className="size-6 hover:text-white" />}
                variant="outline"
                onClick={() => {
                  dispatch(openCreateDeviceModal())
                }}
                className="h-4 xl:h-6 cursor-pointer text-primary
                hover:text-white hover:bg-blue-secondary text-lg font-light"
              >
                Create Device
              </Button>
            </div>
            <div className="flex flex-wrap text-left gap-2">
              {categories.map((category) => getCategorySection(category))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Actions className="justify-start sticky bottom-0 bg-blue-main">
        <button
          type="button"
          className="inline-flex w-full sm:w-auto font-light text-white border
                  border-transparent hover:text-grey-dark"
          onClick={() => {
            dispatch(closeDeviceLibraryModal())
          }}
        >
          Cancel
        </button>
      </Modal.Actions>
    </Modal>
  )
}

export default DeviceLibraryModal
